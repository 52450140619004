import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import styled from 'styled-components';
import { detect } from 'detect-browser';

const browser = detect();

const bubbleSizes = {
    small: {
        mobile: 135,
        575: 180,
        720: 118,
        1024: 146,
        1440: 220,
    },
    normal: {
        mobile: 152,
        575: 224,
        720: 132,
        1024: 183,
        1440: 270,
    },
    normalbig: {
        mobile: 152,
        575: 224,
        720: 160,
        1024: 220,
        1440: 270,
    },
    big: {
        mobile: 200,
        575: 292,
        720: 200,
        1024: 225,
        1440: 308,
    },
    bigCoupled: {
        mobile: 200,
        575: 292,
        720: 200,
        1024: 225,
        1440: 308,
    }
};
const MiddleAlignedContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    & img {
        display: block;
        margin: 0 auto;
    }
    ${props => props.wide && `
        width: 100%;
        padding: 0 10%;
        text-align: left;
        position: relative;
        top: initial;
        left: initial;
        transform: none;
        
    `}
    ${props => props.centred && `
        text-align: center;
        white-space: normal;
        padding: 0;
    `}
    ${props => props.halfWidth && `
        width: 35%;
    `} 
    & * {
        margin: 0;
    }
    @media screen and (max-width: 719px) {
        ${props => props.wide && `
            transform: none;
            top: auto;
            left: auto;
        `}
    }
`;

const Bubble = styled.div`
    position: relative;
    width: 100%;
    padding-top: 100%;
    height: 0;
    color: ${props => props.color};
    background-color: ${props => props.backgroundColor};
    border-radius: 50%;
    text-align: center;
    align-self: center;
   ${props => !props.backgroundColor && `
        padding-top: 0 !important;
        height: auto;

   `};
   @media screen and (min-width: 720px) {
        ${props => !props.backgroundColor && `
            padding-top: 0;
            height: auto;
            width: 100%;
        `};
    }
   @media (min-width: 768px) and (max-width: 1024px) {
        ${props => !props.backgroundColor && `
            padding-top: 0;
            height: auto;
        `};
    }
    & ${MiddleAlignedContainer} {
        & p, & h1, & h2, & h4 {
            color: ${props => props.color};
            padding: 0;
            width: 100%;
            margin: 0;
        }
    }

    &:only-child {
        flex-grow: 0;
        flex-basis: auto;
        @media screen and (max-width: 768px) {
            ${props => !props.backgroundColor && `
            width: 100%;
        `};
        }
    }
`;


const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: ${props => props.justifyContent || 'space-evenly'};
    text-align: center;
    padding: ${props => props.noMargins ? '25px 0;' : '25px 15px;'};
    width: 100%;
    flex: 0 0 100%;
    height: inherit;
    margin: 0 auto;
    p, h4 {
        padding: 0;
    }
    max-width: ${props => props.bubbleWidths['mobile']}px;

    @media screen and (min-width: 575px) {
        max-width: ${props => props.bubbleWidths['575']}px;
    }
    @media screen and (min-width: 720px) {
        max-width: ${props => props.bubbleWidths['720']}px;
    }
    @media screen and (min-width: 1024px) {
        max-width: ${props => props.bubbleWidths['1024']}px;
    }
    @media screen and (min-width: 1440px) {
        max-width: ${props => props.bubbleWidths['1440']}px;
    }
    ${props => props.no_background && `
        & {
            max-width: 100%;
            @media screen and (min-width: 720px) {
                max-width: 50%;
            }
        }
    `}
    &.fixed-width {
        max-width: 36px;
        transform: rotate(90deg);
        @media screen and (min-width: 575px) {
            max-width: 48px;
        }
        @media screen and (min-width: 720px) {
            transform: none;
            max-width: 40px;
        }
        @media screen and (min-width: 1024px) {
            max-width: 60px;
        }
        @media screen and (min-width: 1440px) {
            max-width: 80px;
        }

        & ${MiddleAlignedContainer} {
            position: absolute;
        }
    }
`;

const CoupledBubbles = styled.div`
    display: flex;
    flex: 1 3 100%;
    flex-wrap: nowrap;
    justify-content: space-around;
    &:only-child {
        max-width: 100%;
    }
    & ${Column} {
        @media screen and (max-width: 400px) {
            margin: 0 8px;
        }
    }
    & ${Bubble} {
        width: 100%;
        padding-top: 100%;
    }
    // added these because pct 109 on the list
    @media screen and (min-width: 575px) and (max-width: 630px) {
        margin: 0 7%;
    }
    @media screen and (min-width: 630px) and (max-width: 720px) {
        margin: 0 11%;
    }
`;

const BubbleSection = styled.div`
    /* margin: 0; */
    display: flex;
    padding: 0 30px;
    flex-direction: column;
    flex-wrap: nowrap;
    
    @media screen and (min-width: 720px) {
        flex-wrap: nowrap;
        flex-direction: row;
        ${props => props.noWrap && `
            & ${Bubble} {
                flex-grow: 1;
            }
            & ${Column} {
                min-width: auto;
            }
            & ${Column} p.block-img {
                display: block;
            }
        `};
    }

    @media screen and (min-width: 1024px) {
        padding: 0 50px;
    }
    @media (min-width: 1440px) {
        padding: 0 110px;
    }
    
    
    & ${Column} {
        margin: 0 auto;
        padding: 0;

        @media screen and (min-width: 720px) {
            ${browser.name !== 'edge' && 'margin: 10px;'}
        }
        @media screen and (max-width: 720px) {
            ${props => props.noWrap && `
                & ${Bubble} {
                    &:only-child {
                        padding-top: 0;
                    }
                }
            `};
        }
        & p {
            margin-bottom: 0;
        }

        & h2 {
            line-height: normal;
        } 
    }

    & ${MiddleAlignedContainer} h2, & ${MiddleAlignedContainer} h3 {
        white-space: nowrap;
    }

    justify-content: space-evenly;

    @media screen and (min-width: 720px) {
        flex-direction: row;
        
        & ${Column} {
            ${browser.name !== 'edge' && 'margin: 0 15px;'}
        }
    }
    
    // special case us army spacing
    &.us-army-bubble {
        padding: 25px 0 5px 0;
        & ${CoupledBubbles} {
            padding-bottom: 25px;
        }
        @media screen and (min-width: 720px) {
            padding: 25px 0 15px 0;
        }
    }
     // special case dr phil spacing
      &.dr-phil-bubble {
            h2 {
                margin-bottom: 0;
            }
        }
     @media screen and (min-width: 720px) and (max-width: 1024px) {
         &.dr-phil-bubble {
            max-width: 80%;
            margin: 0 auto;
        }
    }
`;

const BubbleTopOrBottom = styled.div`
    position: relative;
    overflow-wrap: normal;
    flex: 1 1 100%;
    & > * {
        width: 100%;
        left: 0;
        padding: 25px 0;
        @media screen and (min-width: 575px) {
            padding: 25px 0;
        }
        @media screen and (min-width: 720px) {
            padding: 23px 0;
        }
        @media screen and (min-width: 1024px) {
            padding: 25px 0;
        }
        @media screen and (min-width: 1440px) {
            padding: 40px 0;
        }
    }

    & h2 {
        margin-bottom: 16px;
        font-weight: normal;
    }

    & h3 {
        margin-bottom: 16px;
    }

    &:empty {
        display: none;
    }
    &.bubble-top {
        div {
            transform: none;
        }
    }
    &.bubble-bottom {
        div {
            transform: none;
        }
    }
    .contact-page-bubbles-text {
        p {
            text-align: left;
            font-size: 12px;
            @media screen and (min-width: 575px) {
                font-size: 16px;
            }
            @media screen and (min-width: 720px) {
                font-size: 12px;
            }
            @media screen and (min-width: 1024px) {
                font-size: 16px;
            }
            @media screen and (min-width: 1440px) {
                font-size: 20px;
                line-height: 40px;
            }
        }
        @media screen and (max-width: 575px) {
            padding: 25px 0 35px 0;
            &.last {
                padding-bottom: 10px;
            }
        }      
    }
`;

const BubbleWrapper = styled.div`
    &.mobile-alignment {
        & ${BubbleSection} {
            padding-top: 0;
            padding-bottom: 0;
        }
        & ${Column} {
            max-width: 100%;
            @media screen and (min-width: 720px) {
                max-width: 240px;
            }
            @media screen and (min-width: 1024px) {
                max-width: 280px;
            }
            @media screen and (min-width: 1440px) {
                max-width: 440px;
            }
            & ${Bubble} {
                max-width: ${bubbleSizes.big['mobile']}px;
                padding-top: ${bubbleSizes.big['mobile']}px;
                @media screen and (min-width: 575px) {
                    max-width: ${bubbleSizes.big['575']}px;
                    padding-top: ${bubbleSizes.big['575']}px;
                }
                @media screen and (min-width: 720px) {
                    max-width: ${bubbleSizes.big['720']}px;
                    padding-top: ${bubbleSizes.big['720']}px;
                }
                @media screen and (min-width: 1024px) {
                    max-width: ${bubbleSizes.big['1024']}px;
                    padding-top: ${bubbleSizes.big['1024']}px;
                }
                @media screen and (min-width: 1440px) {
                    max-width: ${bubbleSizes.big['1440']}px;
                    padding-top: ${bubbleSizes.big['1440']}px;
                }
            }
        }
    }
    &.force-nowrap ${BubbleSection} {
        flex-direction: row;
        & ${MiddleAlignedContainer} {
            width: 80%;
        }
    }
    @media screen and (min-width: 1024px) {
        .goodlife-bubble:first-child {
            margin-bottom: 0;
        }
    }
`;
const renderBubbles = (fields) => {
    const isContactPage = typeof window !== 'undefined' && window.location.pathname === '/contact';
    const returnObject = [];
    fields && fields.map((bubble, index) => {
        const widths = bubbleSizes[bubble.bubble_size.toLowerCase()];
        returnObject.push(
            <Column key={index}
                className={`${bubble.fixed_width === 'True' ? 'fixed-width' : ''}`}
                no_background={!bubble.background_color}
                bubbleWidths={widths}
                justifyContent='space-evenly'>
                {bubble.text_above &&
                    <BubbleTopOrBottom className="bubble-top">
                        <div>{RichText.render(bubble.text_above)}</div>
                    </BubbleTopOrBottom>
                }
                <Bubble className="bubble" color={bubble.text_color}
                    backgroundColor={bubble.background_color}
                    bubbleWidths={widths}>

                    <MiddleAlignedContainer
                        wide={!bubble.background_color && bubble.fixed_width !== 'True'}>
                        {bubble.text && RichText.render(bubble.text)}
                    </MiddleAlignedContainer>
                </Bubble>
                {bubble.text_below &&
                    <BubbleTopOrBottom className="bubble-bottom">
                        <div className={`${isContactPage ? `contact-page-bubbles-text ${index && 'last'}` : ''}`}>
                            {RichText.render(bubble.text_below)}</div>
                    </BubbleTopOrBottom>
                }
            </Column>
        );
    });
    return returnObject;
};
const Bubbles = ({ data }) => {
    const maxBubbles = data.primary ? data.primary.max : null;
    const fields = Object.assign([], data.fields);
    const coupled = data.primary ? data.primary.coupled === 'True' : false;
    const returnData = [];
    if (coupled) {
        if (fields[1].fixed_width === 'True') {
            returnData.push(<CoupledBubbles>
                {renderBubbles(fields.splice(0, 3))}
            </CoupledBubbles>);
        } else {
            returnData.push(<CoupledBubbles>{renderBubbles(fields.splice(0, 2))}</CoupledBubbles>);
        }
    }
    let bubblesClassName = 'vertical-spacing smaller-spacing';
    if (data.primary.custom_bubble_class) {
        bubblesClassName = ` ${data.primary.custom_bubble_class}`;
    }

    if (maxBubbles) {
        while (fields.length > 0) {
            returnData.push(
                <BubbleSection className={bubblesClassName} key={Math.random()}
                    noWrap={maxBubbles}>{renderBubbles(fields.splice(0, maxBubbles))}</BubbleSection>
            );
        }
    } else {
        returnData.push(renderBubbles(fields));
    }

    return <BubbleWrapper className={`bubbles ${data.label}`}>
        { maxBubbles ? returnData :
            <BubbleSection className={bubblesClassName} noWrap={maxBubbles}>
                { returnData }
            </BubbleSection>
        }
    </BubbleWrapper>;
};

Bubbles.propTypes = {
    data: PropTypes.object,
};

export default Bubbles;
