import React from 'react';
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import pin from '../../assets/pin.svg';

const MapContainer = styled.div`
    height: 100%;
    max-height: 280px;
    width: 100%;
    .gm-fullscreen-control {
        display: none!important;
    }
    a[href^="http://maps.google.com/maps"]{display:none !important}
    a[href^="https://maps.google.com/maps"]{display:none !important}
    
    .gmnoprint a, .gmnoprint span, .gm-style-cc {
        display:none;
    }
    .gmnoprint div {
        background:none !important;
    }
    
    @media screen and (min-width: 575px) {
        max-height: 400px;
    }
    
    @media screen and (min-width: 1024px) {
        max-height: 500px;
    }
    
    @media screen and (min-width: 1440px) {
        max-height: 750px;
    }
    
`;

const PinContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const PinText = styled.div`
    background: ${props => props.theme.main};   
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    height: 26px;
    padding-top: 4px;
    width: 80px;
    
    @media screen and (min-width: 1024px) {
        height: 46px;
        font-size: 20px;
        padding-top: 0;
        width: 120px;
    }
`;

const PinImage = styled.img`
    height: 38px;
    margin-top: 3px;
    width: 23px;
    
    @media screen and (min-width: 1024px) {
        height: 78px;
        margin-top: 10px;
        width: 43px;
    }
`;
const PinAndText = () => <PinContainer>
    <PinText>MCM</PinText>
    <PinImage src={pin} />
</PinContainer>;

class Map extends React.Component {
    render() {
        const { coordinates } = this.props;
        const mapOptions = {
            fullscreenControl: false,
            disableDefaultUI: true,
            panControl: false,
            zoomControl: false,
            scrollwheel: false,
        };
        // const renderMarkers = (map, maps) => {
        //     const marker = new maps.Marker({
        //         position: { lat: coordinates.latitude, lng: coordinates.longitude },
        //         map,
        //         title: 'MCM'
        //     });
        //     return marker;
        // };
        return (
            <MapContainer style={{ height: '100vh', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: '' }}
                    defaultCenter={{ lat: coordinates.latitude, lng: coordinates.longitude }}
                    fullscreenControl={false}
                    options={mapOptions}
                    // onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
                    defaultZoom={16}>
                    <PinAndText
                        lat={40.7441}
                        lng={-73.9866} />
                </GoogleMapReact>
            </MapContainer>
        );
    }
}

Map.propTypes = {
    coordinates: PropTypes.object
};

export default Map;

