/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import Border from '../components/border';
import LinkWithSvg from '../components/link-wth-svg';

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const PublisherInfo = styled.h4`
    color: #212121;
    font-weight: bold;
    padding-bottom: 4px;

    @media screen and (min-width: 1024px) {
        padding-bottom: 25px;
    }
`;

const HiddenContainer = styled.div`
    display: none;

    @media screen and (max-width: 719px) {
        display: block;
        margin-bottom: 32px;
    }
`;

const ArticleLeft = styled.div`
    width: 100%;
    padding-right: 0;
    margin: 0;
    & > * {
        margin: 0 ${props => props.theme.paddings['mobile']};
        &${HiddenContainer} {
           margin: 0; 
        }
    }

    & h2 {
        padding-bottom: 32px;
        font-weight: bold;
        max-width: 90%;
    }

    & p {
        padding-bottom: 32px;
    }

    & > * {
        margin: 0;
        padding: 0;
        // &:not(${HiddenContainer}) {
        //     > p {
        //         padding: 5px ${props => props.theme.paddings['mobile']};
        //     }    
        //     @media (min-width: 575px) {
        //         padding: 14px ${props => props.theme.paddings['575']};
        //     }
        //     @media (min-width: 720px) {
        //         padding: 8px ${props => props.theme.paddings['720']};
        //     }
        //     @media (min-width: 1024px) {
        //         padding: 16px ${props => props.theme.paddings['1024']};
        //     }
        //     @media (min-width: 1440px) {
        //         padding: 20px ${props => props.theme.paddings['1440']};
        //     }
        // }
    }
`;

const TextContainer = styled.div`
    margin: 0;
    padding: 33px 30px 0 30px;
    p {
        padding: 15px 0 17px 0;
    }
    
    @media screen and (min-width: 720px) {
        padding: 0 ${props => props.theme.paddings['575']} 0 ${props => props.theme.paddings['575']}; 
        p {
            padding: 15px 0 0 0;
        }
    }
    
    @media screen and (min-width: 1024px) {
        padding: 0 ${props => props.theme.paddings['1024']} 0 ${props => props.theme.paddings['1024']}; 
        p {
            padding: 17px 0 5px 0;
        }
    }
    
    @media screen and (min-width: 1440px) {
        padding: 0 ${props => props.theme.paddings['1440']} 0 ${props => props.theme.paddings['1440']};  
        p {
            padding: 24px 0 17px 0;
        }
    }
`;

const Spacer = styled.div`
    height: 0;
    margin: 30px ${props => props.theme.paddings['mobile']} 70px ${props => props.theme.paddings['mobile']}; 
    
    @media screen and (min-width: 575px) {
        margin: 46px ${props => props.theme.paddings['575']} 110px ${props => props.theme.paddings['575']}; 
    }
    @media screen and (min-width: 720px) {
        margin: 40px ${props => props.theme.paddings['720']} 100px ${props => props.theme.paddings['720']}; 
    }
    @media screen and (min-width: 1024px) {
        margin: 28px ${props => props.theme.paddings['1024']} 100px ${props => props.theme.paddings['1024']}; 

        h4 {
            line-height: 60px;
        }
    }
    @media screen and (min-width: 1440px) {
        margin: 70px ${props => props.theme.paddings['1440']} 200px ${props => props.theme.paddings['1440']}; 
        h4 {
            padding: 40px 0 60px 0;
        }
    }
`;

const ArticleRight = styled.div`
    width: 75%;
    display: none;
    margin: 0;
    & > * {
        margin: 0;
        
    }
    img {
        margin: auto;
        display: block;
    }
    @media screen and (min-width: 720px) {
        display: block;
        margin-right: ${props => props.theme.paddings['720']}; 
        img {
            max-width: 280px;
            max-height: 204px;
        }
    }
    @media screen and (min-width: 1024px) {
        margin-right: ${props => props.theme.paddings['1024']}; 
        img {
            max-width: 380px;
            max-height: 90%;
        }
       
    }
    @media screen and (min-width: 1440px) {
        display: flex;
        margin-right: ${props => props.theme.paddings['1440']}; 
        img {
            margin-left: auto;
        }
    }
`;

const ArticleContainer = styled.div`
    animation: ${fadeIn} 1s;
    display: flex;
    font-family: ${props => props.theme.fonts.main};
    justify-content: space-around;
    margin: 33px 0 0 0;
   
    &.big {
        flex-direction: column;
        padding: 0px ${props => props.theme.paddings['mobile']};
        margin: 33px 0 30px 0;

        & img {
            max-height: 100vh;
            object-fit: contain;
        }

        & h2 {
            color: ${props => props.theme.black};
            font-style: normal;
            font-weight: bold; 
            margin-bottom: 14px;
        }

        & p {
            color: ${props => props.theme.black};
            font-style: normal;
            font-weight: normal;
            margin: 0 0 17px 0;
            
        }
        @media screen and (min-width: 575px) {
            padding: 0px ${props => props.theme.paddings['575']};
        }
        @media screen and (min-width: 720px) {
            padding: 0px ${props => props.theme.paddings['720']};
        }
        @media screen and (min-width: 1024px) {
            padding: 0px ${props => props.theme.paddings['1024']};
        }
        @media screen and (min-width: 1440px) {
            padding: 0px ${props => props.theme.paddings['1440']};
        }
        @media screen and (min-width: 1024px) {
            margin: 50px 0 30px 0;
        }
        @media screen and (min-width: 1440px) {
            margin: 90px 0 100px 0;
            & h2 {
                margin-bottom: 28px;
                max-width: 87.33%;
            }
            
            & p {
                max-width: 87.33%;
            }
        }
    }
    
    @media screen and (min-width: 575px) {
        margin: 43px 0 20px 0;
    }
    
    @media screen and (min-width: 1024px) {
        ${props => !props.big && `margin: ${props.theme.paddings['1024']} 0 0 0;`}
    }
     
    @media screen and (min-width: 1024px) {
        margin: 95px 0 40px 0;
    }
`;

const BigImage = styled.img`
    height: 280px;
    object-fit: cover;
    width: 100%;
    
    @media screen and (min-width: 575px) {
        height: 350px;
    }
    
    @media screen and (min-width: 720px) {
        height: 350px;
    }    
  
    @media screen and (min-width: 1024px) {
        height: 500px;
        padding-bottom: 0;
    }
    @media screen and (min-width: 1440px) {
        height: 600px;
    }    
`;

const LoadMore = styled.button`
    background: none;
    border: none;
    color: ${props => props.theme.black};
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    line-height: 52px;
    letter-spacing: 0.01em;
    padding: 11px 0 20px 0;
    text-transform: uppercase;
    text-decoration: underline;
    text-align: center;
    width: 100%;
  
    :hover {
        color: ${props => props.theme.main};
    }
    :focus {
        outline: none;
        color: ${props => props.theme.main};
    }
    ${props => props.as === 'div' && `
        :hover {
            color: ${props.theme.black};
        }
        user-select: none;
        padding-bottom: 10px;
    `}
    
    @media screen and (min-width: 575px) {
        font-size: 16px; 
    }
    
    @media screen and (min-width: 720px) {
        font-size: 12px; 
    }
    
    @media screen and (min-width: 1024px) {
        font-size: 16px; 
         padding: 50px;
    }
    
    @media screen and (min-width: 1440px) {
        font-size: 20px;
        line-height: 38px;
    }
`;

const RedirectTopHome = styled.div``;

const Container = styled.section``;

const Article = React.memo(({ data, big }) => {
    const publishText = data.publication ?
        data.publisher && !big ?
            `Published on ${data.publication} by ${data.publisher}` :
            `Published in ${data.publication}` :
        null;
    const imageUrl = data.article_image ? data.article_image.url : '';
    const date = new Date(data.publish_date);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const underImage = `${data.publish_date ?
        `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()} \u2022 ` :
        ''}${`${data.read_length ? `${data.read_length} Min Read \u2605` : ''}`}`;
    const hideArticleInsuffData = !data.article_link && !imageUrl;

    return !hideArticleInsuffData && <section className="media">
        {
            big ?
                <>
                    <BigImage text={underImage} src={imageUrl} />
                    <ArticleContainer className="big">
                        {publishText && <PublisherInfo>{publishText}</PublisherInfo>}
                        <h2>{data.article_title}</h2>
                        {data.article_summary && RichText.render(data.article_summary)}
                        <LinkWithSvg text={'Read Article'} destination={data.article_link.url} outerLink={true} />
                    </ArticleContainer>
                    <Border></Border>
                    <Spacer className="media">
                        <h4>Recent Articles</h4>
                    </Spacer>

                </> :
                <ArticleContainer>
                    <ArticleLeft>
                        <HiddenContainer className="article-image-container">
                            <img src={imageUrl} />
                            {/* {underImage !== '' && <AfterImage>{underImage}</AfterImage>}*/}
                        </HiddenContainer>
                        <TextContainer>
                            <h3>{data.article_title}</h3>

                            {data.article_summary && RichText.render(data.article_summary)}
                            <LinkWithSvg text={'Read Article'}
                                         destination={data.article_link && data.article_link.url} outerLink={true} />
                        </TextContainer>
                    </ArticleLeft>
                    <ArticleRight className="article-image-container">
                        <img src={imageUrl} />
                        {/* {underImage !== '' && <AfterImage>{underImage}</AfterImage>}*/}
                    </ArticleRight>
                </ArticleContainer>
        }

    </section>;
}, (prevProps, nextProps) => prevProps.data === nextProps.data);
Article.displayName = 'Article';
Article.propTypes = {
    data: PropTypes.object,
    big: PropTypes.bool,
};

const ArticleSection = ({ data }) => {
    const articlesPerPage = 4;
    const noOfPages = Math.ceil(data.length / articlesPerPage);
    const [currentPage, setCurrentPage] = useState(1);
    const [scrollLevelBeforeUpdate, setScrollLevel] = useState(0);
    const allElementsLoaded = currentPage === noOfPages;
    useEffect(() => {
        if (currentPage !== 1) {
            window.scrollTo({
                top: scrollLevelBeforeUpdate,
                behavior: 'smooth'
            });
        }
    });
    const renderArticles = (page) => {
        return data.map((article, index) => {
            if (index < page * articlesPerPage) {
                if (index === 0) {
                    return <Article key={index} big data={article} />;
                }
                return <Article key={index} data={article} />;
            }
        });
    };
    return <Container>
        {renderArticles(currentPage)}
        {allElementsLoaded ?
            <RedirectTopHome>
                <LoadMore as={`div`} onClick={() => typeof window !== 'undefined' &&
                    window.scroll({ top: 0, left: 0, behavior: 'smooth' })}
                    tabIndex={0} aria-label={`back to top`}>{`Back to Top`}</LoadMore>
            </RedirectTopHome> :
            <LoadMore tabIndex={0} aria-label={`load more articles`}
                onClick={() => {
                    setScrollLevel(document.body.scrollHeight - 100 + 32);
                    setCurrentPage(currentPage + 1);
                }}>more articles</LoadMore>}
    </Container>;
};

ArticleSection.propTypes = {
    data: PropTypes.array,
};
export default ArticleSection;
