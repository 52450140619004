/* eslint-disable max-lines-per-function */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withResizeDetector } from 'react-resize-detector';
import Hammer from 'react-hammerjs';

const Image = styled.img`
    user-select: none;
`;
const ImageContainerDiv = styled.div`
    width: 100%;
    display: block;
    
    img {
        margin: 0 auto;
    }
     
    @media screen and (min-width: 720px) {
        width: 60%;
    }

    cursor: pointer;
    @media screen and (min-width: 720px) {
        cursor: default;
    }
`;

const Collage = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    white-space: nowrap;
    flex-wrap: nowrap;
    overflow: visible;
    transition: 0.25s all ease;
    @media screen and (min-width: 720px) {
        transform: none !important;
    }
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;

    & ${ImageContainerDiv} {
        margin: 32px 4px;
        flex: 0 0 55%;
        @media screen and (max-width: 719px) {
            &:first-child {
                margin-left: 45px;
                @media screen and (min-width: 575px) {
                    margin-left: 70px;
                }
            }
        }
        
        img {
            display: block;
            width: unset;
            object-fit: contain;
            max-height: 553px;
            margin: 0 auto;
            @media screen and (min-width: 575px) {
                max-height: 561px;
            }
            @media (min-width: 1024px) {
                max-height: 500px;
            }
            @media (min-width: 1440px) {
                max-height: 700px;
            }
        }
    }

    @media (min-width: 720px) {
        white-space: initial;
        overflow: visible;
        max-width: 1040px;
        width: 90%;
        margin: 0 auto;

        justify-content: center;

        & ${ImageContainerDiv} {
            flex: 0 0 40%;
            /* margin: 0 -10%; */
        }
        
        ${props => props.collageStyle === 'Grid' && `
            flex-wrap: wrap;
            margin-bottom: -8%;
            & + * {
                margin-top: 0;
            }

            & ${ImageContainerDiv} {
                width: 50%;
                margin: 0;
                object-fit: contain;
                flex: auto;
                &:nth-child(4n+1) {
                    transform: translate(11%, 11%);
                }
                &:nth-child(4n+2) {
                    transform: translate(-11%, 11%);
                }
                &:nth-child(4n+3) {
                    transform: translate(11%, -21%);
                }
                &:nth-child(4n) {
                    transform: translate(-11%, -11%);
                }
            }
            ${props.size < 4 && `
                margin-bottom: 0;
                & ${ImageContainerDiv} {
                    &:nth-child(2n+1) {
                        transform: translateX(11%);
                    }
                    &:nth-child(2n+2) {
                        transform: translateX(-11%);
                    }
                }
            `}
        `}
        ${props => props.collageStyle === 'Inline' && `
            & ${ImageContainerDiv} {
                margin: 0 -2%;
            }
        `}
    }
`;
const ImageCollageContainer = styled.div`
    position: relative;
`;
const Dot = styled.div`
    width: 6px;
    height: 6px;
    display: inline-block;
    margin: 0 3px;
    border-radius: 50%;
    background-color: ${props => props.selected ? props.theme.main : '#D0D4E1'};
    cursor: pointer;
`;
const Dots = styled.div`
    position: absolute;
    bottom: 6px;
    left: 50%;
    transform: translateX(-50%);
    @media screen and (min-width: 720px) {
        display: none;
    }
`;
const ImageCollage = ({ data, width }) => {
    const { fields, primary } = data;
    const [selected, setSelected] = useState(0);
    const mainRef = useRef();
    const initialRefs = useRef([]);
    const currentDecalation = useRef(0);
    useEffect(() => {
        if (width < 720) {
            const currentSelectedX = initialRefs.current[selected].current.getBoundingClientRect().left;
            const distanceToCentre = (mainRef.current.getBoundingClientRect().width -
                initialRefs.current[selected].current.getBoundingClientRect().width) /
                2;
            mainRef.current.style.transform =
                `translateX(${(distanceToCentre + currentDecalation.current - currentSelectedX)}px)`;

            currentDecalation.current = distanceToCentre + currentDecalation.current - currentSelectedX;
        }
    }, [selected, width]);
    const onHandleSwipe = e => {
        if (Math.abs(e.deltaX) > 5) {
            if (Math.sign(e.deltaX) === -1) {
                if (selected !== initialRefs.current.length - 1) {
                    setSelected(selected + 1);
                }
            } else {
                if (selected !== 0) {
                    setSelected(selected - 1);
                }
            }
        }
    };
    return <Hammer className='vertical-spacing' onSwipe={onHandleSwipe} direction='DIRECTION_HORIZONTAL'>
        <ImageCollageContainer onWheel={onHandleSwipe}>
            <Collage ref={mainRef} size={fields.length} collageStyle={primary.style} onScroll={(e) => {
                e.preventDefault();
            }}>
                {
                    fields.map((field, key) => {
                        const newRef = useRef();
                        if (!initialRefs.current[key]) {
                            initialRefs.current = [...initialRefs.current, newRef];
                        }
                        return <ImageContainerDiv key={key}
                            ref={newRef}
                            onClick={() => {
                                setSelected(key);
                            }}
                            selected={selected === key}>
                            <Image draggable="false" src={field.image.url} />
                        </ImageContainerDiv>;
                    }, true)
                }
            </Collage>
            <Dots>
                {
                    fields.map((field, key) => {
                        return <Dot key={key}
                            onClick={() => {
                                setSelected(key);
                            }}
                            selected={selected === key}>
                        </Dot>;
                    })
                }
            </Dots>
        </ImageCollageContainer>
    </Hammer>;
};
ImageCollage.propTypes = {
    data: PropTypes.any,
    width: PropTypes.number,
};
export default withResizeDetector(ImageCollage);
