/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import React from 'react';
import { RichText } from 'prismic-reactjs';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link, navigate } from 'gatsby';
import Profile from './profile';
import Map from './map';
import ArticleSection from './articleSection';
import LinkWithSvg from '../components/link-wth-svg';
import Bubbles from './bubbles';
import { Picture } from 'react-responsive-picture';
import ImageCollage from './imageCollage';
import Border from './border';
import ReactHammer from 'react-hammerjs';

const HeroImg = styled.img`
    max-height: ${props => props.imageHeight}px;
    margin-left: auto;
    margin-right: auto;
    ${props => props.customheight && `max-height: ${props.customheight}px`};
    ${props => props.noMargins && `
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    `}
    max-width: ${props => props.maxWidth ? `${props.maxWidth}px` : '100%'};
    object-fit: contain;
    ${props => props.maxWidth && !props.widthPercent && 'padding: 0 25px'};
    /* width: ${props => props.maxWidth ? `${props.maxWidth / 1340 * 100}%` : '100%'}; */
    width: ${props => props.widthPercent ? `${props.widthPercent}%` : '100%'};
    ${props => props.maxWidth && 'object-fit: contain'};
    ${props => props.desktopOnly && `
        display: none;
        @media screen and (min-width: 720px) {
            display: block;
        }
    `}
    ${props => props.customheight && `max-height: ${props.customheight}px`};
    ${props => props.fixedHeight && `
        height: 280px;
        object-fit: cover;
        @media screen and (min-width: 575px) {
            height: 400px;
        }
        @media screen and (min-width: 720px) {
            height: 400px;
        }
        @media screen and (min-width: 1024px) {
            height: 500px;
        }
        @media screen and (min-width: 1440px) {
            height: 700px;
        }
    `}
     
    
    @media screen and (min-width: 720px) {
        ${props => props.customheight && `max-height: 100%;`};
    }
    @media screen and (min-width: 1024px) {
        margin-left: auto;
        margin-right: auto;
        padding: 0;
    }
    
    @media screen and (min-width: 1440px) {
        max-width: ${props => props.maxWidth ? `${props.maxWidth}px` : '100%'};
    }

    ${props => props.maxWidth && `
        & {
            height: auto;
        }
    `}
`;

const SideImg = styled.img`
    display: flex;
    height: auto;
    margin: 0;
       
    @media screen and (min-width: 1920px) {
         max-height: ${props => `${props.height}px`};
    }
`;

const ImageContainerDiv = styled.div`
    width: 100%;
    display: block;
    
    img {
        margin: 0 auto;
    }
     
    @media screen and (min-width: 720px) {
        width: 60%;
    }
`;

const TextOverImage = styled.span`
    color: #fff;
    font-family: ${props => props.theme.fonts.main};
    font-size: 28px;
    position: absolute;
    ${props => (props.align === 'Left' ? 'left: 7.5%' : 'right: 12.5%')};
    top: 50%;
`;

const SideText = styled.div`
    align-items: center;
    display: flex;
    width: 100%;
    margin: 0;
    
    @media screen and (min-width: 720px) {
        width: 50%;
    }  
    
    @media screen and (min-width: 1024px) {
        h4 {
            margin-bottom: 1rem;
        }
        p {
            margin-bottom: 25px;
        }
    }
    
    @media screen and (min-width: 1920px) {
        p {
            margin-bottom: 25px;
        }
    }
`;

const SideTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    ${props => props.isBottomSection && 'padding-left: 30px; '};
    margin: 32px 0;

    & h1 {
        padding-left: 0 !important;
    }
    & h2 {
        margin-bottom: 1rem;
    }
    // max-width: 275px;
    @media screen and (min-width: 575px) {
        ${props => props.isBottomSection && 'padding-left: 40px; '};
        // max-width: ${props => props.isBottomSection ? '470px' : '430px'};
    }
    @media screen and (min-width: 720px) {
        ${props => props.isBottomSection && 'padding-left 0;'};
        margin: 0;
        ${props => props.side === 'Right' ? 'margin-right: 5%; ' : 'margin-left: 5%'};
        // max-width: 230px;
    }
    
    @media (min-width: 1024px) {
        /* ${props => props.isBottomSection && 'padding-left 64px;'} */
        // max-width: 278px;
    }
    @media (min-width: 1440px) {
        /* ${props => props.isBottomSection && 'padding-left 110px;'} */
        // max-width: 380px;
    }
`;

const ImageAndText = styled.div`
    background: ${props => props.background && props.background};
    display: flex;
    flex-direction: column-reverse;
    ${ props => props.columnOnMobile && 'flex-direction: column;'};
    justify-content: center;
    padding: ${props => props.isBottomSection ? '0' :
        (props.noPaddingTop ? `0 ${props.theme.paddings['mobile']}` :
            `0 ${props.theme.paddings['mobile']}`)};
    @media screen and (min-width: 575px) {
        padding: ${props => props.isBottomSection ? '0' :
        (props.noPaddingTop ? `0 ${props.theme.paddings['575']}` :
            `0 ${props.theme.paddings['575']}`)};
    }
    @media screen and (min-width: 720px) {
        
        padding: ${props => props.isBottomSection ? '0' :
        (props.noPaddingTop ? `0 ${props.theme.paddings['720']}` :
            `0 ${props.theme.paddings['720']}`)};
        ${props => props.side === 'Left' ? `
            flex-direction: row;
            img {
                margin-right: auto;
                margin-left: 20px;
            }
        ` :
        `
            flex-direction: row-reverse;
            img {
                margin-left: auto;
                // margin-right: 20px;
            }
            ${SideTextContainer} {
                margin-left: auto;
            }
        `}
    }
    @media screen and (min-width: 1024px) {
        padding: ${props => props.isBottomSection ? '0' :
        (props.noPaddingTop ? `0 ${props.theme.paddings['1024']}` :
            `0 ${props.theme.paddings['1024']}`)};
    }
    @media screen and (min-width: 1440px) {
        padding: ${props => props.isBottomSection ? '0' :
        (props.noPaddingTop ? `0 ${props.theme.paddings['1440']}` :
            `0 ${props.theme.paddings['1440']}`)};
    }
    & + & {
        padding-top: 0;
    }
`;

const Text = styled.div`
    & h2 {
        margin-bottom: 1em;
        &:only-child {
            margin-bottom: 0;
        }
    }
    p.block-img img {
        display: block;
        margin: 0 auto;
        min-height: 1px;
    }
    @media screen and (max-width: 720px) {
        p.block-img {
            margin: 30px 0;
        }
    }
    padding: 0 ${props => props.theme.paddings['mobile']};
    & > * {
        // max-width: 275px;
    }
    &.validating {
            margin: 36px 0 2px 0;
    }
    &.good-for-business {
        margin-top: 30px!important;
    }
    @media screen and (min-width: 575px) {
        padding: 0 ${props => props.theme.paddings['575']};
        & > * {
            // max-width: 430px;
        }
         &.validating {
             margin: 43px 0 13px 0;
         }
    }
    @media screen and (min-width: 720px) {
        padding: 0 ${props => props.theme.paddings['720']};
        & > * {
            // max-width: 455px;
        }
        &.validating {
            margin: 33px 0 0 0;
        }
    }
    @media screen and (min-width: 1024px) {
        padding: 0 ${props => props.theme.paddings['1024']};
        & > * {
           //  max-width: 646px;
        }
        &.validating {
            margin: 46px 0 8px 0;
        }
        &.good-life-purple {
            margin-bottom: 0;
        }
        &.good-for-business {
            margin-top: 50px!important;
        }
    }
    
    @media screen and (min-width: 1440px) {
        padding: 0 ${props => props.theme.paddings['1440']};
        & > * {
            max-width: 87.33%;
        }
        
        p.block-img img {
            display: block;
            width: 100%;
            padding: 0 110px;
        }
         &.validating {
            margin: 90px 0 45px 0;
        }
    }
    p.block-img {
        max-width: 100%;
    }
    & + & {
        margin-top: 0;
    }
    & .link-with-svg {
        margin-bottom: 0;
    }
`;

const MiddleAlignedContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    & img {
        display: block;
        margin: 0 auto;
    }
    ${props => props.wide && `
        width: 100%;
        padding: 0 10%;
        text-align: left;
        position: relative;
        
    `}
    ${props => props.centred && `
        text-align: center;
        white-space: normal;
        padding: 0;
    `}
    ${props => props.halfWidth && `
        width: 35%;
    `} 
    & * {
        margin: 0;
    }
    
    @media screen and (max-width: 719px) {
        ${props => props.wide && `
            transform: none;
            top: auto;
            left: auto;
            & > p.block-img:only-child {
                transform: rotate(90deg);
            }
        `}
    }
`;
const Section = styled.section`
    background-color: ${props => props.backgroundColor};
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;
    padding: 0;

    h4, h2 {
        color: ${props => props.textColor === 'dark' ? props.theme.black : props.theme.white};
    }

    p {
        color: ${props => props.textColor === 'dark' ? props.theme.black : props.theme.white};
        ${ props => props.backgroundColor === '#f89828' && `color: ${props.theme.black}`}
    }

    & *:first-child.${HeroImg} {
        margin-top: 0;
    }

    & *:last-child.${HeroImg} {
        margin-bottom: 0;
    }
    h5 {
        max-width: 990px;
        margin: 0 auto;
        text-align: center;
    }
    
    h6 {
        max-width: 990px;
        margin: 0 auto;
        text-align: center;
    }
    
     @media screen and (min-width: 640px) {
         h6 {
             margin-top: 24px;
         }
     }
     
     @media screen and (min-width: 720px) {
         p {
             /* margin-bottom: 40px; */
        }
     }
     & > *:only-child.vertical-spacing {
        margin-top: 0;
        margin-bottom: 0;
     }
`;

const FinalSectionColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: ${props => props.justifyContent || 'space-evenly'};
    padding: 0;
    text-align: center;
    margin: ${props => props.noMargins ? '25px 0;' : '25px 15px;'};
    min-width: 120px;
    width: 100%;
    flex: 1 1 100%;
    height: inherit;
    p, h4, a {
        padding: 0;
    }
    @media screen and (min-width: 500px) {
        max-width: ${props => props.width ? `${props.width}px` : 'auto'} !important;
    }
    @media screen and (min-width: 720px) {
        flex: 1 1 ${props => props.width ? `${props.width}px` : '100%'};
        padding: 0;
        width: auto;
        & ${Text} {
            padding-right: 30px;
        }
    }
    
    @media screen and (min-width: 1441px) {
        max-width: 100% !important;
    }
    & ${Text} {
        min-height: 280px;
        @media screen and (min-width: 575px) {
            min-height: 400px;
        }
        @media screen and (min-width: 720px) {
            min-height: initial;
        }
    }
`;

const GetInTouch = styled.div`
    padding: 0 30px;
    width: 100%;
    
    h2 {
        margin-bottom: 34px;
    }
    
    div {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
    
    h1 {
        display: flex;
        flex-direction: column;
        margin-bottom: 36px!important;
        width: 100%;
    }

    h1:nth-child(5) {
        margin-bottom: 0;
    }
    
    p, a {
        color: ${props => props.theme.black};
        font-family: ${props => props.theme.fonts.main};
        text-transform: lowercase;
        text-transform: capitalize;
        text-decoration: none;
    }

    a {
        text-transform: none;
        &:hover {
            color: #ED1E24;
        }
    }
    
    p:first-child {
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.01em;
        margin-bottom: 3px;
    }
    
    @media screen and (min-width: 575px) {
        h2 {
            margin-bottom: 44px;
        }
        h1 {
            margin-bottom: 45px!important;
        }
        p:first-child {
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 18px;
        }
    }
    
    @media screen and (min-width: 720px) {
        padding: 0 40px;  
        div {
            flex-direction: row;
            flex-wrap: wrap;
        } 
        h1 {
            flex-direction: column;
            width: 33%;
            &:nth-child(2) {
                margin-left: 10%;
            }
            margin-bottom: 11px!important;
        }
        h1:nth-child(3), h1:nth-child(4), h1:nth-child(5) {
            margin-bottom: 0;
        }
        h2 {
            margin-bottom: 0;
        } 
        p:first-child {
            font-size: 14px;
            line-height: 60px;
            margin-bottom: 0;
        }  
    }
    
    @media (min-width: 1024px) {
        padding: 0 64px;
        h2 {
            margin-bottom: 26px;
        }
        h1 {
            margin-bottom: 33px!important;
        }
        p:first-child {
            font-size: 18px;
            line-height: 60px;
        }
    }
    
    @media screen and (min-width: 1440px) {
        padding: 0 110px;
        p:first-child {
            font-size: 24px;
            margin-bottom: 15px;
        }
        h1 {
            margin-bottom: 70px!important;
        }
             
    }
`;

const MiniSquare = styled.div`
    bottom: 0;
    background-color: ${props => props.theme.main};
    height: 80px;
    position: absolute;
    right: 0;
    width: 80px;

    & > svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    
    @media screen and (min-width: 575px) {
        height: 124px; 
        width: 124px;
    }

    @media screen and (min-width: 720px) {
        display: none;
    }
`;

const Square = styled(Link)`
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 70%;
    background-color: #FF4300;
    color: white;
    font-size: 18px;
    white-space: nowrap;
    margin: 0;
    max-height: 600px;
    ${props => props.url && `
        background: url(${props.url});
        background-size: cover;
        background-repeat: no-repeat;
    `}
    ${MiddleAlignedContainer} {
        display: none;
        svg {
            max-width: 100px;
            @media (min-width: 1024px) {
                max-width: 132px;
            }
            @media (min-width: 1440px) {
                max-width: 178px; 
            }
        }
    }
    ${MiniSquare} {
        background-color: #ED1E24;

        & svg {
            path {
                stroke: ${props => props.theme.white};
            }
        }
        
        @media screen and (min-width: 575px) {
            & svg {
                height: 44px;
                width: 22px;
            }
        }
    }
    
    @media (min-width: 720px) {
        font-size: 28px;
        width: 100%;
        ${MiddleAlignedContainer} {
            display: block;
        }
    }
`;

const FinalSection = styled.div`
    display: flex;
    justify-content: space-around;
    padding: 50px 0;
    flex-direction: column;
    width: 100%;
    text-align: left;

    & .link-with-svg {
        align-self: flex-end;
        margin-top: auto;
        padding-top: 30px;
        margin-right: auto;
    }

    & p, & h2 {
        text-align: left;
    }
    & ${Square} {
        padding-top: 280px;
        @media screen and (min-width: 575px) {
            padding-top: 400px;
        }
        @media screen and (min-width: 720px) {
            padding-top: 350px;
        }
        @media screen and (min-width: 1024px) {
            padding-top: 400px;
        }
        @media screen and (min-width: 1440px) {
            padding-top: 600px;
        }
    }
    ${props => props.noPaddingTop && `
        padding: 0;
        margin: 0 auto;
        &:not(.final-section) {
            .link-with-svg {
                margin-top: auto;
            }
        }
        & ${FinalSectionColumn} {
            margin: 0;
            padding: 0;
            & ${Text} {
                display: flex;
                flex-direction: column;
                height: 100%;
                max-height: 280px;
                @media screen and (min-width: 575px) {
                    max-height: 400px;
                }
                @media screen and (min-width: 720px) {
                    max-height: 350px;
                }
                @media screen and (min-width: 1024px) {
                    max-height: 400px;
                }
                @media screen and (min-width: 1440px) {
                    max-height: 600px;
                }
                & h2 {
                    margin-bottom: 16px;
                }
                & p {
                    // max-width: 275px;
                    @media screen and (min-width: 575px) {
                        // max-width: 430px;
                    }
                    @media screen and (min-width: 720px) {
                        // max-width: 250px;
                    }
                    @media screen and (min-width: 1024px) {
                        // max-width: 322px;
                    }
                    @media screen and (min-width: 1440px) {
                        // max-width: 400px;
                    }
                }
            }

            :first-child {
                & p, & h2, & a {
                    padding: 0;
                }
            }
        }
        & ${MiddleAlignedContainer} {
            max-width: 250px;
        } 
    `}
    
    ${props => !props.noPaddingTop && `
        & ${FinalSectionColumn} {
            margin: 0;
            padding: 0 10px;
            @media (min-width: 1024px) {
                :first-child {
                    padding: 0 25px 0 110px;
                }

                :last-child {
                    padding: 0 25px;
                }
            }
        }
        & ${Square} {
            @media screen and (min-width: 720px) {
                margin: 15px auto;
            }
        }
    `}
    
    @media screen and (min-width: 720px) {
        flex-direction: row;
    } 
    
    
`;

const PlayButton = styled.div`
    position: absolute;
    height: 0;
    border-radius: 50%;
    background-color: #cdcdcd66;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    transition: .25s all ease-in;
    pointer-events: none;
    :after {
        content: '';
        position: absolute;
        bottom: 50%;
        left: 50%;
        height: 25%;
        transform: translateX(-33%);
        width: 34%;
        background: linear-gradient(to top right, white 50%, transparent 50%);
        transition: .25s all ease-in;
    }
    :before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        height: 25%;
        transform: translateX(-33%);
        width: 34%;
        background: linear-gradient(to right bottom, white 50%, transparent 50%);
        transition: .25s all ease-in;
    }
    width: 86px;
    padding-top: 86px;

    @media screen and (min-width: 575px) {
        width: 120px;
        padding-top: 120px;
    }
    @media screen and (min-width: 720px) {
        width: 104px;
        padding-top: 104px;
    }
    @media screen and (min-width: 1024px ) {
        width: 130px;
        padding-top: 130px;
    }
    @media screen and (min-width: 1440px) {
        width: 162px;
        padding-top: 162px;
    }
`;

const VideoContainer = styled.div`
    width: 100%;
    margin: 0 auto;
    position: relative;

    &, & img {
        height: 280px;
        @media screen and (min-width: 575px) {
            height: 400px;
        }
        @media screen and (min-width: 720px) {
            height: 400px;
        }
        @media screen and (min-width: 1024px) {
            height: 500px;
        }
        @media screen and (min-width: 1440px) {
            height: 700px;
        }
    }
`;

const VideoThumbnail = styled.img`
    width: 100%;
`;

const VideoOverlay = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    cursor: pointer;
    background: URL(${props => props.url});
    background-size: cover;
    background-position: center;
    :hover {
        ${PlayButton} {
            background-color: #cdcdcdaa;
            :after {
                 // commented because of nr 143 on bug list
                // background: linear-gradient(to top right, ${props => props.theme.main} 50%, transparent 50%)
            }
            :before {
                // background: linear-gradient(to right bottom, ${props => props.theme.main} 50%, transparent 50%)
            }
        }
    }
`;

const Video = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    & iframe {
        width: 100%;
        height: 100%;
    }
`;

const HomeImageAndText = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    @media (min-width: 720px) {
        flex-direction: ${props => props.side === 'left' ? 'row' : 'row-reverse'};
    }
`;

const HomeTextContainer = styled.div`
    padding: 0 ${props => props.theme.paddings['mobile']};
    padding-top: 15px;
    padding-bottom: 35px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    .link-with-svg {
        margin-top: auto;
    }
    
    @media (max-width: 575px) {
        a h2 {
            font-size: 20px;
            line-height: 40px;
        }
    }
    @media (min-width: 575px) {
        width: 100%;
        padding: 0 ${props => props.theme.paddings['575']};
        padding-top: 10px;
        padding-bottom: 35px;
    }
    @media (min-width: 720px) {
        padding: 0 ${props => props.theme.paddings['720']};
        padding-top: 14px;
        padding-bottom: 38px;
    }

    @media (min-width: 1024px) {
        padding: ${props => props.side === 'left' ? '0 65px 0 40px' : '0 40px 0 65px'};
        padding-top: 36px;
        padding-bottom: 60px;
    }
    
    @media (min-width: 1440px) {
        padding: ${props => props.side === 'left' ? '0 110px 0 74px' : '0 74px 0 110px'};
        padding-top: 50px;
        padding-bottom: 60px;
    }
`;
const HomeImageContainer = styled.div`
    position: relative;
    width: 100%;
    overflow: hidden;

    @media (min-width: 640px) {
        flex: 0 0 calc(50% + 30px);
    }
`;

const HomeTagsSection = styled.div`
    color: #8C8C8C;
    margin: 0;
    text-transform: capitalize;
`;

const HomeTags = styled.span``;

const Title = styled.h2`
    cursor: pointer;
    &:hover {
        color: #ed1e24;
    }
`;

const SideImageH = styled.div`
    width: 100%;
    height: 100%;
    padding-top: 0;
    position: relative;
    background: url(${props => props.src});
    background-position: top center;
    background-size: cover;
    cursor: pointer;
`;

const TriangleDecoration = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 30px;

    & .side1, & .side2 {
        position: absolute;
        width: calc(50% - 30px);
        height: 100%;
        background-color: ${props => props.theme.white};
    }

    & .side1 {
        left: 0;
        &::after {
            position: absolute;
            border-style: solid;
            border-width: 30px 0 0 30px;
            border-color: transparent transparent transparent ${props => props.theme.white};
            content: '';
            left: 100%;
        }
    }

    & .side2 {
        right: 0;
        &::after {
            position: absolute;
            border-style: solid;
            border-width: 0 0 30px 30px;
            border-color: transparent transparent ${props => props.theme.white} transparent;
            content: '';
            right: 100%;
        }
    }

    @media (min-width: 720px) {
        ${props => props.side && (props.side === 'left' ? `
        right: 0;
        height: 100%;
        width: 30px;

        & .side1, & .side2 {
            position: absolute;
            height: calc(50% - 30px);
            width: 100%;
            background-color: ${props => props.theme.white};
        }

        & .side1 {
            top: 0;
            &::after {
                position: absolute;
                border-style: solid;
                border-width: 0 30px 30px 0 ;
                border-color: transparent ${props.theme.white} transparent transparent ;
                content: '';
                top: 100%;
                left: 0;
            }
        }
        & .side2 {
            bottom: 0;
            &::after {
                position: absolute;
                border-style: solid;
                border-width: 0 0 30px 30px;
                border-color: transparent transparent ${props => props.theme.white} transparent;
                content: '';
                bottom: 100%;
                right: 0;
            }
        }
    ` :
        `
        left: 0;
        height: 100%;
        width: 30px;
        & .side1, & .side2 {
            position: absolute;
            height: calc(50% - 30px);
            width: 100%;
            background-color: ${props => props.theme.white};
        }
        & .side1 {
            top: 0;
            &::after {
                position: absolute;
                border-style: solid;
                border-width: 30px 30px 0 0 ;
                border-color: ${props.theme.white} transparent transparent transparent ;
                content: '';
                top: 100%;
                left: 0;
            }
        }
        & .side2 {
            bottom: 0;
            &::after {
                position: absolute;
                border-style: solid;
                border-width: 0 30px 30px 0;
                border-color: transparent transparent transparent ${props => props.theme.white};
                content: '';
                bottom: 100%;
                right: 0;
            }
        }
    `)}
    }
`;

const SmallImageH = styled.img`
    /* max-height: 110px; */
    width: 75%;
    margin: auto 0;
    
    @media screen and (min-width: 1024px) {
        /* max-height: 120px; */
        width: 80%;
    }
    @media screen and (min-width: 1440px) {
        max-height: none;
        width: 100%;
    }
`;

const CustomCta = styled.div`
    padding: 0 ${props => props.theme.paddings['mobile']};

    @media (min-width: 575px) {
        padding: 0 ${props => props.theme.paddings['575']};
    }
    @media (min-width: 720px) {
        padding: 0 ${props => props.theme.paddings['720']};
    }
    @media (min-width: 1024px) {
        padding: 0 ${props => props.theme.paddings['1024']};
    }
    @media (min-width: 1440px) {
        padding: 0 ${props => props.theme.paddings['1440']};
    }
`;


const TextContainer = styled.div` 
    position: absolute;
    width: 71%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    & > * {
        margin: 16px 0;
    }
    
    @media screen and (max-width: 350px) {
        h5 {
            width: 285px;
            margin: 16px auto;
        }
    }
`;

const Grid = styled.section`
    display: flex;
    flex-wrap: wrap;
    & h4 {
        font-weight: normal;
        text-transform: none;
    }
`;
const GridSquare = styled.div`
    background-color: ${props => props.backgroundColor};
    color: ${props => props.color};
    flex: 1 0 auto;
    height:auto;
    text-align: center;
    width: 100%;
    
    p {
        text-align: left;
        color: ${props => props.color};
        @media screen and (max-width: 574px) {
            width: 235px; 
            margin: 0 auto;
            font-size: 14px;
        }
        font-size: 14px;
     
        @media screen and (min-width: 575px) {
            font-size: 18px;
        }
        @media screen and (min-width: 720px) {
            font-size: 12px;
        }
        @media screen and (min-width: 1024px) {
            font-size: 16px;
        }
        @media screen and (min-width: 1440px) {
            font-size: 20px;
        }
    }

    // changed this from 640 to 720 because of request for manifesto page squares 
    @media (min-width: 720px) {
        width: 50%;
    }

    &:before {
        content:'';
        float:left;
        padding-top: 350px;
        @media screen and (min-width: 575px) {
            padding-top: 500px;
        }
        @media screen and (min-width: 720px) {
            padding-top: 325px;
        }
        @media screen and (min-width: 1024px) {
            padding-top: 400px;
        }
        @media screen and (min-width: 1440px) {
            padding-top: 600px;
        }
        & ${TextContainer} {
            max-width: 235px;
            @media screen and (min-width: 575px) {
                max-width: 370px;
            }
            @media screen and (min-width: 720px) {
                max-width: 224px;
            }
            @media screen and (min-width: 1024px) {
                max-width: 320px;
            }
            @media screen and (min-width: 1440px) {
                max-width: 468px;
            }
        }
    }

    &:only-child {
        & ${TextContainer} {
            min-width: 315px;
            @media screen and (min-width: 575px) {
                max-width: 447px;
            }
            @media screen and (min-width: 720px) {
                max-width: 555px;
            }
            @media screen and (min-width: 1024px) {
                max-width: 791px;
            }
            @media screen and (min-width: 1440px) {
                max-width: 992px;
            }
        }
        &:before {
            padding-top: 350px;
            @media screen and (min-width: 575px) {
                padding-top: 400px;
            }
            @media screen and (min-width: 720px) {
                padding-top: 275px;
            }
            @media screen and (min-width: 1024px) {
                padding-top: 350px;
            }
            @media screen and (min-width: 1440px) {
                padding-top: 500px;
            }
        }
    }
    position: relative;
`;
const ResponsiveImageSource = styled(Picture)`
    margin-top: auto;
    display: block;
    ${props => props.compiledCSS}
`;
const Slice = ({ data }) => {
    if (!data) {
        return null;
    }
    if (data.type === 'image') {
        data.type = 'hero_image';
    }
    switch (data.type) {
        case 'text': {
            if (data.primary.text[0].type === 'preformatted') {
                return <GetInTouch className="get-in-touch vertical-spacing"
                    dangerouslySetInnerHTML={{ __html: data.primary.text[0].text }} />;
            }
            const extraClass = (data.primary && data.primary.custom_text_class) || '';

            return <Text className={`text${data.label ? ` ${data.label}` : ''} vertical-spacing ${extraClass}`}>
                {data.primary.text && RichText.render(data.primary.text)}
            </Text>;
        }

        case 'hero_image': {
            return <HeroImg
                className={`hero-image ${(!data.primary.no_margins &&
                    data.primary.top_image_on_mobile !== 'True' || data.primary.max_width ||
                    data.primary.width) ?
                    'vertical-spacing' : ''} 
                    ${data.primary.top_image_on_mobile === 'True' ? 'desktop-only' : ''}`}
                src={data.primary.hero_image.url}
                objectFit={data.primary.max_width || data.primary.fixed_height !== 'true' ? 'contain' : 'cover'}
                fixedHeight={data.primary.fixed_height === 'true'}
                nospacingmobile={data.primary.no_spacing_mobile === 'true'}
                customheight={data.primary.special_height_mobile}
                desktopOnly={data.primary.top_image_on_mobile === 'True'}
                noMargins={data.primary.no_margins}
                imageHeight={data.primary.hero_image.dimensions.height}
                widthPercent={data.primary.width}
                maxWidth={data.primary.max_width} />;
        }

        case 'image___text': {
            if (data.primary.side_image) {
                const miloPhoneSection = data.primary.side_text && data.primary.side_text[0].text === 'Direct Communication';
                const classByPage = data.primary.custom_section_class ?
                    `${data.primary.custom_section_class}-text-image ${data.primary.image_side} ${miloPhoneSection && ' phone-section'}` : '';
                const sideTextClass = data.primary.custom_section_class ?
                    `${data.primary.custom_section_class}-side-text` : '';

                return (
                    <ImageAndText
                        className={`vertical-spacing smaller-spacing ${classByPage}`}
                        side={data.primary.image_side}
                        isBottomSection={data.primary.bottom_section === 'true'}
                        noPaddingTop={data.primary.no_margins_section}
                        columnOnMobile={data.label === 'imagetoponmobile'}
                        background={data.primary.section_background_color}>
                        {
                            data.primary.text_over_image &&
                            <TextOverImage align={data.primary.image_side}>
                                {data.primary.text_over_image}
                            </TextOverImage>
                        }
                        <ImageContainerDiv className='image-text-side-image'>
                            <SideImg
                                height={data.primary.side_image.dimensions.height}
                                src={data.primary.side_image.url}
                                dimensions={data.primary.side_image.dimensions}
                            />
                        </ImageContainerDiv>

                        <SideText className='image-text-side-text'>
                            <SideTextContainer side={data.primary.image_side}
                                className={sideTextClass}
                                isBottomSection={data.primary.bottom_section === 'true'}>
                                {data.primary.side_text && RichText.render(data.primary.side_text)}
                                {
                                    data.primary.section_link &&
                                    <LinkWithSvg text={data.primary.section_link && data.primary.section_link[0].text}
                                        outerLink={true}
                                        destination={data.primary.section_link &&
                                            data.primary.section_link[0].spans[0].data.url}
                                        exportSvg={true} />
                                }
                            </SideTextContainer>
                        </SideText>
                    </ImageAndText>
                );
            } else {
                return <></>;
            }
        }

        case 'team': {
            return <Profile data={data} />;
        }

        case 'relationship': {
            return <Section backgroundColor={data.primary.section.background_color}
                textColor={data.primary.section.text_color}>
                {
                    data.primary.section.body && data.primary.section.body.map((slice, index) => {
                        return <Slice key={index} data={slice} />;
                    })
                }
            </Section>;
        }

        case 'info_bubble': {
            return <Bubbles data={data} />;
        }

        case 'map': {
            if (data.primary.static_image) {
                return <a target="_blank"

                    rel="noopener noreferrer"
                    href={`https://www.google.com/maps/place//@${data.primary.map_location.latitude},${data.primary.map_location.longitude},18z/data=!4m3!1m2!3m1!2zNDDCsDQ0JzI4LjMiTiA3M8KwNTknMDUuOSJX`}>
                    <HeroImg
                        className="hero-image"
                        src={data.primary.static_image.url}
                        noMargins
                        fixedHeight
                        objectFit="cover"
                    ></HeroImg>
                </a >;
            } else {
                return <Map image={data.primary.static_image} coordinates={data.primary.map_location} />;
            }
        }

        case 'video': {
            if (data.primary.video_link) {
                const thumbnail = data.primary.thumbnail;

                return <VideoContainer >
                    <VideoThumbnail src={thumbnail.url} />
                    <Video
                        dangerouslySetInnerHTML={{ __html: data.primary.video_link.html }}>
                    </Video>
                    <VideoOverlay onClick={e => {
                        e.target.parentNode.getElementsByTagName('iframe')[0].src += '&autoplay=1';
                        e.target.parentNode.removeChild(e.target);
                    }}
                        url={thumbnail.url}>
                        <PlayButton />
                    </VideoOverlay>
                </VideoContainer>;
            } else {
                return <></>;
            }
        }

        case 'next_item_cta': {
            const imageUrl = data.primary.background_image ? data.primary.background_image.url : null;
            const nextItemRangri = data.primary.next_item._meta.uid === 'rangri';

            return <FinalSection className="final-section" noPaddingTop={imageUrl}>
                <FinalSectionColumn>
                    <Text className='vertical-spacing vertical-spacing-padding'>
                        {data.primary.text && RichText.render(data.primary.text)}
                        <LinkWithSvg outer={false} text={data.primary.cta_text || 'Next Project'}
                            destination={`/product/${data.primary.next_item._meta.uid}`} />
                    </Text>

                </FinalSectionColumn>
                <FinalSectionColumn noMargins={imageUrl} justifyContent={imageUrl && 'flex-end'}>
                    <ReactHammer onTap={() => {
                        navigate(`/product/${data.primary.next_item._meta.uid}`);
                    }}>
                        <Square to={`/product/${data.primary.next_item._meta.uid}`} url={imageUrl}>
                            {imageUrl ? <>
                                <MiddleAlignedContainer halfWidth centred>
                                    <svg preserveAspectRatio="xMidYMid meet" viewBox="0 0 180 180" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M89.2148 1L178.215 90L89.2148 179"
                                            stroke={nextItemRangri ? '#212121' : '#fff'} strokeWidth="2" />
                                        <path d="M177 91L2.00001 91"
                                            stroke={nextItemRangri ? '#212121' : '#fff'} strokeWidth="2" />
                                    </svg>
                                </MiddleAlignedContainer>
                                <MiniSquare>
                                    <svg width="17" height="31" viewBox="0 0 17 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.3421 2.36842L14.6579 15.6316"
                                            stroke="white" strokeWidth="2" strokeLinecap="square" />
                                        <path d="M2.3421 28.6316L14.6579 15.3684"
                                            stroke="white" strokeWidth="2" strokeLinecap="square" />
                                    </svg>
                                </MiniSquare>
                            </> :
                                <MiddleAlignedContainer
                                    wide centred>
                                    {`Next Project: ${data.primary.next_item.title}`}
                                </MiddleAlignedContainer>
                            }
                        </Square>
                    </ReactHammer>
                </FinalSectionColumn>
            </FinalSection>;
        }

        case 'article': {
            return <ArticleSection data={data.fields} />;
        }

        case 'grid': {
            return <Grid className='grid'>{data.fields.map((field, index) => {
                return <GridSquare
                    key={index}
                    color={field.color}
                    backgroundColor={field.background_color}
                    className='grid-square'>
                    <TextContainer>{field.text && RichText.render(field.text)}</TextContainer>
                </GridSquare>;
            })}</Grid>;
        }

        case 'download_link': {
            return <Text className="vertical-spacing" >
                <LinkWithSvg destination={data.primary.file.url}
                    outerLink={true}
                    downloadSvg={true}
                    download={data.primary.file.name}
                    text={data.primary.downloadText} />
            </Text>;
        }

        case 'home_image_and_text': {
            const { fields, primary } = data;
            const { small_image, section_title, case_link, main_image, image_side } = primary;

            return (
                <HomeImageAndText className='home' side={image_side}>
                    <HomeImageContainer className='home-image-container'>
                        <SideImageH onClick={() => {
                            navigate(`/product/${case_link[0].spans[0].data.uid}`)
                                ;
                        }} src={main_image.url} />
                        <TriangleDecoration side={image_side}>
                            <div className='side1' />
                            <div className='side2' />
                        </TriangleDecoration>
                    </HomeImageContainer>
                    <HomeTextContainer
                        className='home-image-container'
                        side={image_side}>
                        <a
                            style={{ textDecoration: 'none' }}
                            href={`/product/${case_link[0].spans[0].data.uid}`}>
                            <Title>
                                {section_title[0].text}
                            </Title>
                        </a>
                        <HomeTagsSection className="tags">
                            {
                                fields && fields.map((field, f) =>
                                    <React.Fragment key={f}>
                                        <HomeTags key={f}>
                                            {field.tags && field.tags[0].text}
                                        </HomeTags>
                                        {(f !== fields.length - 1) && ', '}
                                    </React.Fragment>
                                )
                            }
                        </HomeTagsSection>
                        <SmallImageH src={small_image.url} />
                        <LinkWithSvg text={case_link[0].text}
                            destination={`/product/${case_link[0].spans[0].data.uid}`} outerLink={false} />
                    </HomeTextContainer>
                </HomeImageAndText>
            );
        }

        case 'image_collage': {
            return <ImageCollage data={data}></ImageCollage>;
        }
        case 'dotted_border': {
            return <Border
                className={`vertical-spacing ${data.primary.hide_desktop === 'true' ? 'dotted-border-mobile' : ''}`}
                hideDesktop={data.primary.hide_desktop === 'true'} />;
        }

        case 'custom_cta': {
            return <CustomCta >
                <LinkWithSvg text={data.primary.placeholder[0].text} outerLink={false} destination={null}
                    goToSection={'getInTouch'} />
            </CustomCta>;
        }

        case 'responsive_image': {
            const images = data.primary.relationship.images;
            let compiledCSS = '';
            const sources = images.map(image => {
                const newCompiledCSS = `
                    ${image.full_width ? 'width: 100%;' : ''}
                    ${image.max_height ? `max-height: ${image.max_height}px;` : ''}
                    ${image.position ? `object-position: ${image.image_position.toLowerCase()}px;` : ''}
                    ${image.image_size ? `object-fit: contain;` : 'object-fit: cover;'}
                    ${image.image_position ? `object-position: ${image.image_position.toLowerCase()};` : ''}
                `;
                const source = {};
                if (image.image) {
                    source.srcSet = image.image.url;
                }
                if (image.responsive_width) {
                    source.media = `(min-width: ${image.responsive_width}px)`;
                    compiledCSS =
                        `${compiledCSS}\n@media screen and (min-width: ${image.responsive_width}px){\n${newCompiledCSS}}`;
                } else {
                    compiledCSS = `${compiledCSS}\n${newCompiledCSS}`;
                }
                return source;
            }).reverse();
            return <ResponsiveImageSource
                        className="responsive-image"
                        compiledCSS={compiledCSS}
                        src={images[0].image.url}
                        sources={sources} />;
        }
        default: {
            return <></>;
        }
    }
};

Slice.propTypes = {
    data: PropTypes.object
};

export default Slice;
