import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import arrowRightSvg from '../../assets/next-arrow.svg';
import exSvg from '../../assets/export.svg';
import dwnloadSvg from '../../assets/download.svg';

if (typeof document !== 'undefined' && !('scrollBehavior' in document.documentElement.style)) {
    // safari does not support smooth scroll
    (async () => {
        const { default: smoothScroll } = await import(
            /* webpackChunkName: 'polyfill-modern' */
            'smoothscroll-polyfill'
        );
        smoothScroll.polyfill();
    })();
}

// component for red links with arrow or other svg all around site
// arrow right svg is default
// if others svg .. send it as name prop

const SectionLinkContainer = styled.div`
    outline: none;
    white-space: nowrap;
    a, span {
        align-items: center;
        color: #ed1e24;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        font-weight: bold;
        letter-spacing: 0.01em;
        word-spacing: -0.25em;
        outline: none;
        text-decoration: none;
        text-transform: uppercase;     
    }
    a, a:visited { outline: none; }
        a:hover,
        a:active,
        a:focus {
          background: #fff;  
    }
`;

const SectionLink = styled(Link)`  
    align-items: center;
    display: flex;
    outline: none;
`;

const SectionLinkNotLink = styled.div`
    align-items: center;
    display: flex;
    outline: none;
`;

const SvgImage = styled.img`
    align-items: center;
    cursor: pointer;
    display: flex;

    /* ${props => props.export && `
        height: 11.95px;
        margin: 0 0 2px 10px;
        
        @media screen and (min-width: 575px) {
            height: 13.95px;
            margin: 0 0 0 11px;
        }
        @media screen and (min-width: 720px) {
            height: 10.53px;
            margin: 0 0 2px 7px!important;
        }
        @media screen and (min-width: 1024px) {
            height: 13.82px;
            margin: 0 0 0 11px!important;;
        }
        @media screen and (min-width: 1440px) {
            height: 19.25px;
            margin-left: 15px!important;
        }
    `};
    
    ${props => props.arrow && `
        height: 8px;
        max-width: 3.85px;
        margin: 0 0 0 7px;

        @media screen and (min-width: 575px) {
            height: 10px;
            max-width: 4.82px;
            margin: 0 0 0 11px;
        }
        @media screen and (min-width: 720px) {
            height: 6px;
            margin: 0 0 0 7px;
            max-width: 2.89px;
        }
        @media screen and (min-width: 1024px) {
            height: 10px;
            max-width: 4.81px;
            margin-left: 9px;
        }
        @media screen and (min-width: 1440px) {
            height: 12.5px;
            margin: 2px 0 0 11px;
            max-width: 6px;
        }
    `};
    
    ${props => props.download && `
        height: 15px;
        margin: 3px 0 0 9px;

        @media screen and (min-width: 575px) {
            height: 18px;
        }
        @media screen and (min-width: 720px) {
            height: 12px;
            margin: 1px 0 0 6px;
        }
        @media screen and (min-width: 1024px) {
            height: 16px;
            margin: 3px 0 0 8px;
        }
        @media screen and (min-width: 1440px) {
            height: 20px;
            margin: 5px 0 0 12px;
        }
    `}; */
`;

const LinkWithSvg = data => {
    const { text, exportSvg, destination, outerLink, downloadSvg, goToSection } = data;
    let source = arrowRightSvg;
    let icon = <>&#xe903;</>;
    let isArrow = true;
    let isExport = false;
    let isDownload = false;
    if (downloadSvg) {
        source = dwnloadSvg;
        icon = <>&#xe900;</>;
        isArrow = false;
        isDownload = true;
    }
    if (exportSvg) {
        source = exSvg;
        icon = <>&#xe904;</>;
        isArrow = false;
        isExport = true;
    }

    const scrollToSection = id => {
        const element = typeof document !== 'undefined' && document.getElementById(id);
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    if (outerLink) {
        return (
            <SectionLinkContainer className='link-with-svg'>
                <a target='_blank' rel='noopener noreferrer' href={destination}
                    tabIndex={0} aria-label={`outer link to article`}>
                    {/* <span>{text}</span> */}
                    <span className="icon">{text} {icon}</span>
                    {/* <SvgImage src={source} arrow={isArrow} export={isExport} download={isDownload} /> */}
                </a>
            </SectionLinkContainer>
        );
    }

    return (
        <SectionLinkContainer className='link-with-svg'>
            {goToSection ?
                <SectionLinkNotLink onClick={() => scrollToSection(goToSection)}
                    tabIndex={0} aria-label={`link to ${text} `}>
                    <span className="icon">{text} {icon}</span>
                    {/* <SvgImage src={downloadSvg ? dwnloadSvg : arrowRightSvg} arrow={!downloadSvg} /> */}
                </SectionLinkNotLink> :
                <SectionLink to={destination} tabIndex={0} aria-label={`link to ${text}`}>
                    <span className="icon">{text} {icon}</span>
                    {/* <SvgImage src={downloadSvg ? dwnloadSvg : arrowRightSvg} arrow={!downloadSvg} /> */}
                    {/* <span className="icon">{icon}</span> */}
                </SectionLink>}
        </SectionLinkContainer>
    );
};

export default LinkWithSvg;
