/* eslint-disable max-lines-per-function */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import linkedIn from '../../assets/linkedin.svg';
import linkedInHover from '../../assets/linkedInHover.svg';

const ProfileContainer = styled.div`
    /* background: ${props => props.isPartner ? props.theme.partner : props.theme.white}; */
    color: #212121;
    h2 {
        padding: 30px;
        margin: 0;
    }
    .second {
        margin-top: 22px;
    }
   
    @media screen and (min-width: 720px) {
        .second {
            margin-top: 0;
        }
    }
   
    @media screen and (min-width: 1024px) {
        h2 {
           padding: 37px 64px 17px 64px;
       }
    }
    
    @media screen and (min-width: 1440px) {
        h2 {
           padding: 76px 110px 10px 110px;
       }
       margin-bottom: 65px;
    }
`;

const ProfileMemberContainer = styled.div`
    /* background: ${props => props.isPartner ? props.theme.partner : props.theme.white}; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: ${props => props.isPartner ? '0 30px' : '0'};
    
    @media screen and (min-width: 720px) {
        flex-direction: row;
        justify-content: space-between;
        max-height: 625px;
    }
    
    @media screen and (min-width: 1024px) {
        max-height: none;
        padding: ${props => props.isPartner ? '0px 64px' : '0'};
    }
    
    @media screen and (min-width: 1440px) {
        max-height: none;
        padding: ${props => props.isPartner ? '0px 110px' : '0'};
    }
`;

const ProfileImage = styled.img`
    ${props => props.isPartner && `
        border-radius: 50%;
    `}
    width: ${props => props.isPartner ? '238px' : ''};
    height: ${props => props.isPartner ? '238px' : ''};

    @media screen and (min-width: 575px) {
        width: ${props => props.isPartner ? '324px' : ''};
        height: ${props => props.isPartner ? '32px' : ''};
    }

    @media screen and (min-width: 720px) {
        width: ${props => props.isPartner ? '176px' : ''};
        height: ${props => props.isPartner ? '176px' : ''};
    }
    
    @media screen and (min-width: 1024px) {
        width: ${props => props.isPartner ? '280px' : ''};
        height: ${props => props.isPartner ? '280px' : ''};
    }
    
    @media screen and (min-width: 1440px) {
        width:  ${props => props.isPartner ? '380px' : ''};
        height: ${props => props.isPartner ? '380px' : ''};
    }
`;

const ProfileBackground = styled.div`  
    background: URL(${props => props.src});
    background-size: contain;
    height: auto;
    margin: 0;
    padding-top: 100%;
    
    @media screen and (min-width: 720px) {
        width: 100%;
    }
`;

const ProfileLeftOrTopSide = styled.div`
    ${props => !props.isPartner && 'height: 280px'};
    width: ${props => props.isPartner ? '232px' : '100%'};
    margin: 0 auto;
    ${props => !props.isPartner && `
            & ${ProfileBackground} {
                padding-top: 280px;
                position: relative;
                background-size: cover;
                @media screen and (min-width: 575px) {
                    padding-top: 350px;
                }
                @media screen and (min-width: 720px) {
                    padding-top: 300px;
                    &:before {
                        content: "";
                        position: absolute;
                        right: 0;
                        top: 0;
                        height: 50%;
                        width: 100px;
                        background: linear-gradient(to left bottom, white 50%, transparent 50%);
                    }
                    &:after {
                        content: "";
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        width: 100px;
                        height: 50%;
                        background: linear-gradient(to left top, white 50%, transparent 50%);
                    }
                }
                @media screen and (min-width: 1024px) {
                    padding-top: 400px;
                }
                @media screen and (min-width: 1440px) {
                    padding-top: 500px;
                }
            } 
        `}
    @media screen and (min-width: 575px) {
        ${props => props.isPartner && 'width: 324px;'}; 
    }
    
    @media screen and (min-width: 720px) {
        width: ${props => props.isPartner ? '176px' : '48%'};
        ${props => !props.isPartner && 'height: auto'}; 
    }    
    @media screen and (min-width: 1024px) {
        width: ${props => (props.isPartner ? '275px' : '48%')};
    }
    
    @media screen and (min-width: 1440px) {
        width: ${props => (props.isPartner ? '380px' : '48%')};
    }
`;

const ContactPerson = styled.div`
    display: flex;
    flex-direction: row;
    margin: 0;
    display: ${props => props.mobile ? 'flex' : 'none'};
    padding: 0 30px;
    
    @media screen and (min-width: 720px) {
        display: ${props => props.mobile ? 'none' : 'flex'};
        margin: ${props => props.align ? '40px 0' : '15px 0 0 0'};
        padding: ${props => props.align ? '0 30px' : '0'};    
        width: 100%;
        justify-content: ${props => props.align ? 'left' : 'center'};   
    }

    @media screen and (min-width: 1024px) {
        padding: 0;
        margin: ${props => props.align && '40px 64px'};
    }
    
    @media screen and (min-width: 1440px) {
        margin: ${props => props.align && '50px 110px'};
        ${props => !props.align && 'margin-top: 30px;'};
    }
`;

const EmailLinkedIn = styled.a`
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    color: #212121;
    font-weight: bold;
    padding: 0 20px 0 0;
    text-decoration: none;

    &:hover {
        color: #ED1E24;
    }

    img {
        height: ${props => props.lnk ? '14px' : '12px'};
        margin-right: 10px;
        margin-top: 2px;
        ${props => props.lnk && 'margin-bottom: 3px;'}
    }
    
    span {
        text-decoration: underline;
        margin-right: 12.5px;
    }
    
    font-size: 14px;
    line-height: 54px;
    @media screen and (min-width: 575px) {
        font-size: 16px;
        line-height: 54px;
        img {
            height: ${props => props.lnk ? '16px' : '14px'};
        }
        span {
             margin-right: 13.5px;
        }
    }

    @media screen and (min-width: 720px) {
        font-size: 12px;
        line-height: 54px;
        img {
            height: ${props => props.lnk ? '14px' : '12px'};
            margin-bottom: 5px;
        }
        span {
             margin-right: 8.5px;
        }
    }
    
    @media screen and (min-width: 1024px) {
        font-size: 16px;
        line-height: 54px;
        span {
            margin-bottom: 3px;
            margin-right: 9.7px;
        }
        img {
            height: ${props => props.lnk ? '16px' : '14px'};
        }
    }
    
    @media screen and (min-width: 1440px) {
        font-size: 20px;
        line-height: 50px;
        img {
            height: ${props => props.lnk ? '20px' : '16px'};
        }
        span {
            margin-bottom: 3px;
            margin-right: 12.7px;
        }
    }
`;


const Name = styled.div`
    font-family: ${props => props.theme.fonts.main};
    font-weight: bold;
    padding: ${props => props.isTeam ? '0' : (props.isPartner ? '20px 0 5px 0' : '33px 30px 5px 30px')};
    text-transform: capitalize;
    font-size: 16px;
    line-height: 24px;
    @media screen and (min-width: 575px) {
        padding: ${props => props.isTeam ? '0' : (props.isPartner ? '10px 0 0 0' : '26px 30px 5px 30px')};
        font-size: 20px;
        line-height: 60px;
    }
    
    @media screen and (min-width: 720px) {
        font-size: 14px;
        line-height: 28px;
        padding: 0;
    }  
    
    @media screen and (min-width: 1024px) {
       font-size: 20px;
       line-height: 32px;
    }  
    
    @media screen and (min-width: 1440px) {
        font-size: 24px;
        line-height: 48px;
        padding: 0;
    }  
`;

const Job = styled.div`
    font-weight: normal;
    text-transform: capitalize;
    padding: 0;
    ${props => !props.isPartner && !props.isTeam && `
        padding: 0 ${props.theme.paddings['mobile']};
        @media screen and (min-width: 575px) {
            padding: 0 {props.theme.paddings['575']};
        }
        @media screen and (min-width: 720px) {
            padding: 0 {props.theme.paddings['720']};
        }
        @media screen and (min-width: 1024px) {
            padding: 0 {props.theme.paddings['1024']};
        }
        @media screen and (min-width: 1440px) {
            padding: 0 {props.theme.paddings['1440']};
        }
    `}
    
    font-size: 14px;
    line-height: 26px;
    @media screen and (min-width: 575px) {
        font-size: 16px;
        line-height: 50px;
    }
    @media screen and (min-width: 720px) {
       font-size: 12px;
       line-height: 24px;
       padding: 0;
    }
    @media screen and (min-width: 1024px) {
       font-size: 16px;
       line-height: 32px;
    }
    @media screen and (min-width: 1440px) {
       font-size: 20px;
       line-height: 48px;
    }  
`;

const ProfileRightOrBottomSide = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    ${props => props.isTeam && `height: 0`};
    padding: ${props => props.isTeam ? '100% 0 0 0' : '0'};
    .profile-absolute-container {
        position: absolute;
        top: 10px;
        left: 20px;
        @media screen and (min-width: 575px) {
            top: 30px;
            left: 40px;
        }
        @media screen and (min-width: 720px) {
            top: 14px;
            left: 20px;
        }
        @media screen and (min-width: 1024px) {
            top: 18px;
            left: 30px;
        }
        @media screen and (min-width: 1440px) {
            top: 26px;
            left: 40px;
        }
    }
    width: 100%;
          
    & ${ContactPerson} {
        margin-left: 0;
    }      
    
    @media screen and (min-width: 575px) {
         /* ${props => props.isTeam && `height: ${props.height}px; min-height: 286px`}; */
    }
    @media screen and (min-width: 720px) {
        ${props => props.isPartner && 'padding: 0 0 0 30px;'};
        width: ${props => props.isPartner ? 'calc(100% - 275px)' : (props.isTeam ? '100%' : '45%')};
    }    
    
    @media screen and (min-width: 1024px) {
        ${props => props.isPartner && 'padding: 0 80px 0 80px;'};
        width: ${props => props.isPartner ? 'calc(100% - 275px)' : (props.isTeam ? '100%' : '45%')};
        background-color: ${props => (props.isHovered ?
        props.theme.main : props.theme.white)};
        ${props => props.isHovered && ` & ${Job}, & ${Name} {
             color: ${props.theme.white};
              }
        `}
    }
    
    @media screen and (min-width: 1440px) {
        padding: ${props => props.isTeam ? '100% 0 0 0' : (props.isPartner ? '0 0 0 120px;' : '0')};
        width: ${props => props.isPartner ? 'calc(100% - 380px)' : (props.isTeam ? '100%' : '45%')};
    }
    ${props => props.isTeam && `height: 0`};
    padding: ${props => props.isTeam ? '100% 0 0 0' : '0'};
    .profile-absolute-container {
        position: absolute;
    }
`;

const Description = styled.p`
    padding: ${props => props.isPartner ? '12px 0 0 0' : '24px 30px'};
    text-align: left;
    max-width: 275px;
    @media screen and (min-width: 575px) {
        padding: 30px 0 0 0;
        max-width: 430px;
    } 
    @media screen and (min-width: 720px) {
        max-width: 250px;
    } 
    @media screen and (min-width: 1024px) {
        max-width: 350px;
    }  
    @media screen and (min-width: 1440px) {
        max-width: 478px;
    } 
`;

const TeamContainer = styled.div`
   display: flex;
   flex-direction: column;
`;

const TeamList = styled.div`display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   & ${Name}, & ${Job} {
       font-size: 14px;
       line-height: 28px;
       @media screen and (min-width: 575px) {
           font-size: 16px;
           line-height: 32px;
       }
       @media screen and (min-width: 720px) {
           font-size: 12px;
           line-height: 24px;
       }
       @media screen and (min-width: 1024px) {
           font-size: 16px;
           line-height: 32px;
       }
       @media screen and (min-width: 1440px) {
           font-size: 20px;
           line-height: 40px;
       }
   }
`;

const TeamMember = styled.div`
    /* cursor: pointer; */
    display: flex;
    flex-direction: column;
    width: 50%;

    @media screen and (min-width: 720px) {
        width: 25%;
    }
`;

const LinkedIn = styled.a`
    cursor: pointer;
    padding: 15px 0 0 0;
    img {
        height: 14px;
        width: 14px;
    }
    
    @media screen and (min-width: 575px) {
        padding: 25px 0 0 0;
        img {
            height: 18px;
            width: 18px;
        } 
    }
    @media screen and (min-width: 720px) {
        padding: 15px 0 0 0;
        img {
            height: 11px;
            width: 11px;
        } 
    }
    @media screen and (min-width: 1024px) {
        padding: 27px 0 0 0;
        img {
            height: 14px;
            width: 14px;
        } 
    }
    @media screen and (min-width: 1440px) {
        padding: 25px 0 0 0;
        img {
            height: 20px;
            width: 20px;
        } 
    }    
`;

const Profile = ({ data }) => {
    const { fields, label } = data;
    const [hoveredElement, setHoveredElement] = useState(null);
    const renderTeamContainer = _renderTeamContainer(fields, hoveredElement, setHoveredElement);
    const isPartner = label === 'partner';
    if (isPartner) {
        return (
            <ProfileContainer isPartner={isPartner} className={'general vertical-spacing'}>
                {fields &&
                    fields.map((profile, p) => {
                        const { first_and_lastname, position, description, email, linkedin, portrait,
                            profile_orientation, } = profile;
                        return (<React.Fragment key={p}>{
                            profileMemberContainer(first_and_lastname, position, description, email, linkedin,
                                portrait, profile_orientation, isPartner)
                        }</React.Fragment>);
                    })}
            </ProfileContainer>
        );
    } else {
        return (
            <ProfileContainer className={'general'}>
                {renderTeamContainer}
            </ProfileContainer>
        );
    }

};

const _renderTeamContainer = (fields, hoveredElement, setHoveredElement) => {
    const [height, setHeight] = useState(null);
    const pic = useRef(null);

    useEffect(() => {
        if (pic.current) {
            setHeight(pic.current.offsetHeight);
        }
    }, [pic]);
    return (
        <TeamContainer>
            <TeamList>
                {fields && fields.map((profile, p) => {
                    const { first_and_lastname, position, linkedin, portrait } = profile;
                    const isHoveredElement = hoveredElement === p;

                    return (
                        <TeamMember key={p}
                        // onMouseOver={() => setHoveredElement(p)}
                        // onMouseOut={() => setHoveredElement(null)}
                        >
                            {p === 0 ? <ProfileImage src={portrait.url} ref={pic} /> :
                                <ProfileImage src={portrait.url} />}

                            <ProfileRightOrBottomSide isTeam={true} isHovered={isHoveredElement} height={height}>
                                <div className="profile-absolute-container">
                                    <Name isTeam={true}>{first_and_lastname[0].text}</Name>
                                    <Job isTeam={true}>{position && position[0].text}</Job>
                                    {linkedin && <LinkedIn href={linkedin} target='_blank' rel='noopener noreferrer'>
                                        <img src={isHoveredElement ? linkedInHover : linkedIn} />
                                    </LinkedIn>}
                                </div>

                            </ProfileRightOrBottomSide>
                        </TeamMember>
                    );
                })}
            </TeamList>
            {/* <MobileBorderSpacer /> */}
        </TeamContainer>
    );

};

const profileMemberContainer = (first_and_lastname, position, description, email, linkedin,
    portrait, profile_orientation, isPartner) => (
    <>
        <ProfileMemberContainer layout={profile_orientation} isPartner={isPartner}>
            <ProfileLeftOrTopSide isPartner={isPartner}>
                {isPartner ? <ProfileImage src={portrait.url} /> : <ProfileBackground src={portrait.url} />}
                {isPartner ? contactPerson(email, linkedin, false) : contactPerson(email, linkedin, false, true)}
            </ProfileLeftOrTopSide>
            <ProfileRightOrBottomSide isPartner={isPartner}>
                <Name isPartner={isPartner}>{first_and_lastname[0].text}</Name>
                <Job isPartner={isPartner}>{position && position[0].text}</Job>
                {description && description[0].text && (
                    <Description isPartner={isPartner}>{description[0].text}</Description>
                )}
            </ProfileRightOrBottomSide>
        </ProfileMemberContainer>
        {contactPerson(email, linkedin, true)}
    </>
);

const contactPerson = (email, linkedin, isMobile, align) => (
    <ContactPerson mobile={isMobile} align={align}>
        {email && <EmailLinkedIn
            href={`mailto:${email}`}
            target='_blank'
            rel='noopener noreferrer'>
            <span className="icon"> &#xe901; </span><span> Email </span>
        </EmailLinkedIn>}
        {
            linkedin && <EmailLinkedIn href={linkedin} target='_blank' rel='noopener noreferrer' lnk>
                <span className="icon"> &#xe902; </span> <span> LinkedIn </span>
            </EmailLinkedIn>
        }
    </ContactPerson >
);

Profile.propTypes = {
    data: PropTypes.object.isRequired,
};

export default Profile;
