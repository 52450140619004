import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

if (typeof document !== 'undefined' && !('scrollBehavior' in document.documentElement.style)) {
// safari does not support smooth scroll
    (async () => {
        const { default: smoothScroll } = await import(
            /* webpackChunkName: 'polyfill-modern' */
            'smoothscroll-polyfill'
        );
        smoothScroll.polyfill();
    })();
}


const BottomArrowScrollUp = styled.div`
    position: relative;
    z-index: 3;
    @media (min-width: 720px) {
        display: none;
    }
`;

const Arrow = styled.div`
    align-items: center;
    bottom: ${props => props.media ? '0' : '80px'};
    display: flex;
    justify-content: center;
    height: 80px;
    position: absolute;
    right: 0;
    transform: rotate(-90deg);
    width: 80px;
    
    @media screen and (min-width: 575px) {
        bottom: ${props => props.media ? '0' : '124px'};
        height: 124px;
        width: 124px;
        svg {
             height: 44px;
             width: 22px;
        }
    }
`;

const BottomArrowUp = props => (
    <BottomArrowScrollUp className='bottom-arrow-mobile'>
        <Arrow onClick={() => window.scroll({ top: 0, left: 0, behavior: 'smooth' }) } media={props.media}>
            <svg width='17' height='31' viewBox='0 0 17 31' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M2.3421 2.36842L14.6579 15.6316'
                      stroke='#ED1E24' strokeWidth='2' strokeLinecap='square' />
                <path d='M2.3421 28.6316L14.6579 15.3684'
                      stroke='#ED1E24' strokeWidth='2' strokeLinecap='square' />
            </svg>
        </Arrow>
    </BottomArrowScrollUp>
);

BottomArrowUp.propTypes = {
    media: PropTypes.bool
};

export default BottomArrowUp;

