import styled from 'styled-components';
import line from '../../assets/dotted-line.svg';
import lineSmall from '../../assets/dotted-line-small.svg';

const Border = styled.div`
    background-image: url(${lineSmall});
    background-repeat: repeat-x;
    background-position: center;
    height: 2px;
    margin: 0 ${props => props.theme.paddings['mobile']};
    @media (min-width: 575px) {
        margin: 0 ${props => props.theme.paddings['575']};
    }
    @media (min-width: 720px) {
        margin: 0 ${props => props.theme.paddings['720']};
        background-image: url(${line});
    }
    @media (min-width: 1024px) {
        margin: 0 ${props => props.theme.paddings['1024']};
        ${props => props.hideDesktop && 'display: none;'}

    }
    @media (min-width: 1440px) {
        margin: 0 ${props => props.theme.paddings['1440']};
    }
`;

export default Border;
